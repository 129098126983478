import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { apolloClientHolder, createSliceFoundation, EntityDescriptor, EntityEditorPage, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { csvComment, csvNewLine, MultiCsvEditor, MultiCsvEditorRRC, regexCsvText } from "@crispico/foundation-react/components/multiCsvEditor/MultiCsvEditor";
import React from "react";
import { Segment } from "semantic-ui-react";
import _ from 'lodash';
import { GanttAssignmentPage, GanttAssignmentPageRRC } from './GanttAssignmentPage';
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { ID, TABLE_PAGE_ICON } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import gql from "graphql-tag";
import { GanttAssignmentHistogram, GanttAssignmentHistogramRRC } from "./GanttAssignmentHistogram";
import { IMPORT_ENTITIES_FROM_CSV } from "./queries";
import { GanttAssignmentTablePageRRC } from "./GanttAssignmentTablePage";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";

export type GanttAssignmentEntities = { [key: string]: { [key: number]: any } };
export type ResourcesData = { available?: number, currentlyUsed?: number, needed?: number, hide: { [key: string]: number[] } };

export const sliceGanttAssignmentEntityEditorPage = createSliceFoundation(class Ext extends SliceEntityEditorPage {
    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
        entities: {} as GanttAssignmentEntities,
        // resourcesData was added because the GA algorithm DynamicProgramming needs to know how many EquipmentResource are in the input
        // csv (number of available resources) and the output csv (number of needed resources) in order to initalize the resource +/- selector
        resourcesData: { hide: {} } as ResourcesData
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),
    }
});

export class GanttAssignmentEntityEditorPage extends EntityEditorPage<PropsFrom<typeof sliceGanttAssignmentEntityEditorPage>> {

    protected inputMultiCsvEditorRef = React.createRef<MultiCsvEditor>();
    protected outputMultiCsvEditorRef = React.createRef<MultiCsvEditor>();
    protected ganttAssignmentPageRef = React.createRef<GanttAssignmentPage>();
    protected ganttAssignmentHistogramRef = React.createRef<GanttAssignmentHistogram>();;

    constructor(props: PropsFrom<typeof sliceGanttAssignmentEntityEditorPage>) {
        super(props);
        this.saveEntity = this.saveEntity.bind(this);
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceGanttAssignmentEntityEditorPage>) {
        this.componentDidUpdateInternal(prevProps);
        if (!prevProps || (prevProps.entity?.inputEntitiesCsv !== this.props.entity?.inputEntitiesCsv) || (prevProps.entity?.outputEntitiesCsv !== this.props.entity?.outputEntitiesCsv)) {
            this.importAllEntities();
        }
    }

    protected renderTabButtons() {
        return <Segment className="buttonBar EntityEditorFormSimple_bar">
            {this.renderButtons({ hideDuplicate: true })}
        </Segment>;
    }

    protected saveEntity(entity: any) {
        this.props.dispatchers.setInReduxState({ entity });
        this.onSave();
    }

    protected async importEntitiesFromJson(entities: { [key: string]: { [key: number]: any } }, csv: string) {
        const json = (await apolloClientHolder.apolloClient.query({ query: IMPORT_ENTITIES_FROM_CSV, variables: { csv } })).data["ganttAssignmentService_importEntitiesFromCsv"];
        const object = JSON.parse(json);
        const simpleNames: string[] = [];

        const list = csv.split(regexCsvText).filter(x => x && x.trim());
        const columns: { [key: string]: string[] } = {};
        for (let i = 0; i < list.length; i = i + 2) {
            columns[JSON.parse(list[i].substring(csvComment.length, list[i].length - csvNewLine.length)).entity] = list[i + 1].substring(0, list[i + 1].indexOf("\n")).split(",").map(c => c.substring(c.indexOf(" ")));
        }

        Object.keys(object).forEach(entityType => {
            const simpleName = entityType.slice(entityType.lastIndexOf(".") + 1);
            simpleNames.push(simpleName);
            if (!entities[simpleName]) {
                entities[simpleName] = {};
            }
            object[entityType].forEach((entity: any) => {
                entities[simpleName][entity.id] = _.pick(entity, columns[simpleName]);
            });
        });
        return simpleNames;
    }

    protected async importAllEntities() {
        if (!this.props.entity) {
            return;
        }
        const { inputEntitiesCsv, outputEntitiesCsv } = this.props.entity;
        const entities: { [key: string]: { [key: number]: any } } = {};
        if (inputEntitiesCsv) {
            await this.importEntitiesFromJson(entities, inputEntitiesCsv);
        }
        const resourcesData: ResourcesData = { hide: {} };
        let inputEquipmentsIds: string[] = [];
        if (entities["EquipmentResource"] && Object.keys(entities["EquipmentResource"]).length > 0) {
            inputEquipmentsIds = Object.keys(entities["EquipmentResource"]);
            resourcesData.needed = inputEquipmentsIds.length;
            resourcesData.available = resourcesData.needed;
            resourcesData.currentlyUsed = resourcesData.needed;
        }
        if (outputEntitiesCsv && outputEntitiesCsv.trim().length > 0) {
            const entitiesImportedFromOutput = await this.importEntitiesFromJson(entities, outputEntitiesCsv);
            if (entitiesImportedFromOutput.includes("EquipmentResource")) {
                // input equipments can be found in output too, needs to take just difference
                const outputEquipmentsIds: string[] = Object.keys(entities["EquipmentResource"]);
                const outputERLength = inputEquipmentsIds.length > 0 ? _.difference(outputEquipmentsIds, inputEquipmentsIds).length : outputEquipmentsIds.length;
                resourcesData.needed = resourcesData.needed ? resourcesData.needed + outputERLength : outputERLength;
                resourcesData.available = resourcesData.needed;
                resourcesData.currentlyUsed = resourcesData.needed;               
            }
        }
        if (!resourcesData.available) {
            resourcesData.available = 0;
        }
        if (!resourcesData.needed) {
            resourcesData.needed = 0;
        }
        if (!resourcesData.currentlyUsed) {
            resourcesData.currentlyUsed = 0;
        }
        this.props.dispatchers.setInReduxState({ entities, resourcesData });
    }

    protected getExtraTabPanes() {
        return super.getExtraTabPanes()?.concat([
            {
                routeProps: { path: "/inputEntitiesCsvEditor" }, menuItemProps: { icon: "file alternate outline", content: _msg("GanttAssignment.inputEntitiesCsv.label") },

                commit: () => {
                    if (this.inputMultiCsvEditorRef.current) {
                        this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, inputEntitiesCsv: this.inputMultiCsvEditorRef.current.getCsv() } });
                    }
                },

                render: () => {
                    return <>
                        {this.renderTabButtons()}
                        <MultiCsvEditorRRC id="multiCsvEditorInput" csvText={this.props.entity?.inputEntitiesCsv} ref={this.inputMultiCsvEditorRef} />
                    </>
                }
            },
            {
                routeProps: { path: "/outputEntitiesCsvEditor" }, menuItemProps: { icon: "file alternate", content: _msg("GanttAssignment.outputEntitiesCsv.label") },

                commit: () => {
                    if (this.outputMultiCsvEditorRef.current) {
                        this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, outputEntitiesCsv: this.outputMultiCsvEditorRef.current.getCsv() } });
                    }
                },

                render: () => {
                    return <>
                        {this.renderTabButtons()}
                        <MultiCsvEditorRRC id="multiCsvEditorOutput" csvText={this.props.entity?.outputEntitiesCsv} ref={this.outputMultiCsvEditorRef} />
                    </>
                }
            },
            {
                routeProps: { path: "/gantt" },
                menuItemProps: { icon: "chart bar", content: "Gantt" },
                render: () => <GanttAssignmentPageRRC id="GanttAssignmentPageRRC" ref={this.ganttAssignmentPageRef} entity={this.props.entity} entities={this.props.entities} resourcesData={this.props.resourcesData} saveEntity={this.saveEntity} />
            },
            {
                routeProps: { path: "/table" },
                menuItemProps: { icon: TABLE_PAGE_ICON, content: "Table" },
                render: () => <GanttAssignmentTablePageRRC id="GanttAssignmentTablePageRRC" entities={this.props.entities} />
            },
            {
                routeProps: { path: "/histogram" },
                menuItemProps: { icon: "chart pie", content: "Histogram" },
                render: () => <GanttAssignmentHistogramRRC id="GanttAssignmentHistogramRRC" ref={this.ganttAssignmentHistogramRef} entities={this.props.entities} />
            },
        ]);
    }
}

export class GanttAssignmentEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "GanttAssignment", defaultSort: { field: "flightsStartDate", direction: "DESC" }
        });
    }

    protected customize() {
        this.addFieldDescriptor({ name: "flightsStartDate", type: FieldType.date })
        this.addFieldDescriptor({ name: "flightsEndDate", type: FieldType.date })
        this.addFieldDescriptor({ name: "inputEntitiesCsv", type: FieldType.text })
        this.addFieldDescriptor({ name: "outputEntitiesCsv", type: FieldType.text, optional: true })
        this.infoEditor.slice = sliceGanttAssignmentEntityEditorPage.setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = GanttAssignmentEntityEditorPage;
        this.isInDefaultColumnConfig(true, "id", "name", "algorithmType", "flightsStartDate", "flightsEndDate", "params", "origin", "organization");
        this.defaultFilter = Filter.createForClient("flightsStartDate", FilterOperators.forDate.today, '');
    }

    protected getInfoEditor() {
        const result = super.getInfoEditor();
        result.routeProps!.routeIsModal = false;
        return result;
    }

    getGraphQlFieldsToRequest(fields?: string[]) {
        return super.getGraphQlFieldsToRequest(fields) + " params { id name inputEntitiesFilters }";
    }

    async getLastGanttAssignment() {
        const query = gql(`query ganttAssignmentService_findByFilter($params: FindByFilterParamsInput) {
            ganttAssignmentService_findByFilter(params: $params) { 
                results { id }
            }
        }`);
        return (await apolloClientHolder.apolloClient.query({
            query, variables: FindByFilterParams.create().sorts([{ field: ID, direction: "DESC" }]).pageSize(1)
        })).data["ganttAssignmentService_findByFilter"].results[0];
    }
}
