import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { DummyToRememberPeopleToCast, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditor, FieldEditorProps, IFormValuesHolder, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { EntityFilterMode, FilterFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FilterFieldEditor";
import { FilterFieldRenderer } from "@crispico/foundation-react/entity_crud/fieldRenderers/FilterFieldRenderer";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EntityNameFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/EntityNameFieldEditor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import React, { ReactElement, ReactNode } from "react";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { Grid } from "semantic-ui-react";
import { DEFAULT_COMPOSED_FILTER } from "@crispico/foundation-react/components/CustomQuery/BlocklyEditorTab";
import _ from "lodash";


export class GanttAssignmentParamsEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "GanttAssignmentParams"
        });
    }

    protected customize() {
        this.addFieldDescriptor({ name: "name", type: FieldType.string })
        this.addFieldDescriptor({ name: "flightAndObjectActionFilter", type: FieldType.filter, entityName: "FlightAndObjectAction", filterable: false, sortable: false })
        this.addFieldDescriptor(new GanttAssignmentParams_InputEntitiesFilterFieldDescriptor())
    }
}

export class GanttAssignmentParams_InputEntitiesFilterFieldDescriptor extends FieldDescriptor {

    constructor() {
        super();
        this.name = "inputEntitiesFilters";
        this.filterable = false;
        this.sortable = false;
    }

    static computeFilterFieldDescriptorName(id: number): string {
        return "filter-" + id;
    };

    renderFieldEditor(formikProps: IFormValuesHolder<any>, _?: DummyToRememberPeopleToCast): ReactElement {
        return <GanttAssignmentParams_InputEntitiesFilterFieldEditor formikProps={formikProps} />;
    }

    protected renderFieldInternal(_: any, __: FieldRendererProps, entity: any): ReactNode {
        const filters = entity.inputEntitiesFilters ? JSON.parse(entity.inputEntitiesFilters) : {};
        return <div className="flex-container-row gap5">
            {Object.keys(filters).map((entityName, index) => {
                const filterFd = new FieldDescriptor();
                filterFd.name = GanttAssignmentParams_InputEntitiesFilterFieldDescriptor.computeFilterFieldDescriptorName(index);
                filterFd.type = FieldType.filter;
                filterFd.entityName = entityName;
                return <div className="felx-container-row flex-center gap10">
                    {entityDescriptors[entityName].getLabel()}
                    <FilterFieldRenderer entity={entityName} value={JSON.stringify(filters[entityName])} fieldDescriptor={filterFd} />
                </div>
            })}
        </div>
    }
}

export class GanttAssignmentParams_InputEntitiesFilterFieldEditor extends FieldEditor<string, FieldEditorProps> {

    protected getFilters() {
        const { inputEntitiesFilters } = this.props.formikProps!.values;
        return inputEntitiesFilters ? JSON.parse(inputEntitiesFilters) : {};
    }

    protected updateFilter(entityName: string, filter: string) {
        const filters = this.getFilters();
        filters[entityName] = filter ? filter : DEFAULT_COMPOSED_FILTER;
        this.props.formikProps!.setFieldValue("inputEntitiesFilters", JSON.stringify(filters));
    }

    protected updateEntityNames(entityNames: string[]) {
        let newFilters = {} as any;
        const filters = this.getFilters();
        let field;
        entityNames.forEach((entityName, index) => {
            newFilters[entityName] = filters[entityName] || DEFAULT_COMPOSED_FILTER;
            field = GanttAssignmentParams_InputEntitiesFilterFieldDescriptor.computeFilterFieldDescriptorName(index);
            this.props.formikProps!.setFieldValue(field, newFilters[entityName]);
        })
        this.props.formikProps!.setFieldValue("inputEntitiesFilters", JSON.stringify(newFilters));
        // force update to rerender
        this.forceUpdate();
    }

    componentDidMount(): void {
        const filters = this.getFilters();
        let field;
        Object.keys(filters).map((entityName, index) => {
            field = GanttAssignmentParams_InputEntitiesFilterFieldDescriptor.computeFilterFieldDescriptorName(index);
            this.props.formikProps!.setFieldValue(field, filters[entityName]);
        })
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const filters = this.getFilters();
        return <div className="flex-container gap10">
            <EntityNameFieldEditor multiple={true} value={Object.keys(filters)} onChangeValue={values => this.updateEntityNames(values)} closeOnChange />
            {Object.keys(filters).map((entityName, index) => {
                const filterFd = new FieldDescriptor();
                filterFd.name = GanttAssignmentParams_InputEntitiesFilterFieldDescriptor.computeFilterFieldDescriptorName(index);
                filterFd.type = FieldType.filter;
                filterFd.showPreviewButton = true;
                filterFd.entityName = entityName;
                filterFd.mode = EntityFilterMode.OBJECT;
                return <Grid className="no-padding-margin">
                    <Grid.Row className="no-padding-margin">
                        <Grid.Column width={4}>
                            <div className="wh100 flex-center">
                                {entityDescriptors[entityName].getLabel()}
                            </div>
                        </Grid.Column>
                        <Grid.Column className="no-padding-margin flex-grow">
                            <FilterFieldEditor scriptableUiId={String(index)} formikProps={this.props.formikProps as IFormValuesHolder<any>} fieldDescriptor={filterFd} onChangeValue={(value) => this.updateFilter(entityName, value)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            })}
        </div>
    }
}
