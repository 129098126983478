import React, { RefObject } from "react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { GanttDoubleDisplayMode, GanttDoubleOptions, GanttDoublePage, GanttDoublePageRRC } from "pages/gantt/GanttDouble";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Button, Dropdown, Form, FormField, Header, Input, Modal, Popup, Segment } from "semantic-ui-react";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { COPY_GANTT_ASSIGNMENT_TO_DB, COPY_GANTT_ASSIGNMENT_TO_DB_EM, COPY_INPUT_DATA_FROM_DB, CREATE_TASKS, DELETE_OUTPUT_DATA_FROM_DB, GET_GANTT_ASSIGNMENT_ALGORITHMS, RECALCULATE_GANTT_ASSIGNMENT, RUN_GANTT_ASSIGNMENT_ALGORITHM } from "./queries";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import _ from "lodash";
import { AlgorithmType, CopyInputDataFromDbConfigInput } from "apollo-gen/globalTypes";
import { GanttAssignmentCopyInputDataButtonRRC, GanttAssignmentEntityRequirement } from "./ganttButtons/GanttAssignmentCopyInputData";
import { GanttAssignmentDeleteDataButtonRRC } from "./ganttButtons/GanttAssignmentDeleteData";
import { ganttAssignmentEntityDescriptor } from "AppEntityDescriptors";
import { GanttAssignmentEntities, ResourcesData } from "./GanttAssignmentEntityDescriptor";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Moment } from "moment";
import moment from "moment";
import { Organization } from "@crispico/foundation-react";
import { GanttTasksMode } from "pages/gantt/GanttTasks";
import { Sorting } from "pages/gantt/GanttResources";

export enum CopyOutputToDbMode {
    SERVICE = "Service",
    ENTITY_MANAGER = "EntityManager",
}

export enum GanttAssignmentOperation {
    DELETE_DATA = "DELETE_DATA",
    COPY_INPUT_DATA_FROM_DB = "COPY_INPUT_DATA_FROM_DB",
    CREATE_TASKS = "CREATE_TASKS",
    RUN_ALGORITHM = "RUN_ALGORITHM",
    COPY_OUTPUT_DATA_TO_DB_SERVICE = "COPY_OUTPUT_DATA_TO_DB_SERVICE",
    COPY_OUTPUT_DATA_TO_DB_EM = "COPY_OUTPUT_DATA_TO_DB_EM",
    DELETE_OUTPUT_DATA_FROM_DB = "DELETE_OUTPUT_DATA_FROM_DB",
    RECALCULATE = "RECALCULATE"
}

export interface GanttAssignmentParams {
    flightAndObjectActionFilter: string,
    inputEntitiesFilters: string,
}

export interface GanttAssignment {
    id: number
    flightsStartDate: number,
    flightsEndDate: number,
    inputEntitiesCsv: string,
    outputEntitiesCsv: string,
    algorithmType: AlgorithmType,
    params: GanttAssignmentParams,
    origin: GanttAssignment,
    organization: Organization,
    readOnly: boolean
}

export const GANTT_DOUBLE_OPTIONS_PER_ALGORITHM: { [key: string]: GanttDoubleOptions } = {
    [AlgorithmType.PYTHON]: {
        ganttTasksOptions: {
            displayMode: GanttTasksMode.FLIGHT_TASKS,
            hideAssignedTasks: false,
        },
        ganttResourcesOptions: {
            sortBy: Sorting.MISSIONS_TOTAL_TIME,
            groupBy: "HumanResourceSchedule",
            hideGroupBy: true,
            showMessageButton: false
        }
    },
    [AlgorithmType.DYNAMIC_PROGRAMMING]: {
        ganttTasksOptions: {
            displayMode: GanttTasksMode.FLIGHT_TASKS,
            hideAssignedTasks: false,
        },
        ganttResourcesOptions: {
            sortBy: Sorting.MISSIONS_TOTAL_TIME,
            groupBy: "EquipmentResource",
            hideGroupBy: true,
            showMessageButton: false
        }
    }
}

class GanttAssignmentPageState extends State {
    currentlyUsedResources = 0;
    algorithms: { [key: string]: { entityRequirements: GanttAssignmentEntityRequirement[] } } = {};
    selectedAlgorithm = "";
    popupOpened = false;
    modalOpenedForRecalculate = false;
    modalOpenedForDeleteNotStartedMissions = false;
    confirmationModalOpenedByOperation?: GanttAssignmentOperation;
    selectDatesModalOpenedByOperation?: GanttAssignmentOperation;
}

class GanttAssignmentPageReducers<S extends GanttAssignmentPageState = GanttAssignmentPageState> extends Reducers<S> {
    updateConfirmationModalOpenedByOperation(operation?: GanttAssignmentOperation) {
        this.s.confirmationModalOpenedByOperation = operation;
    }

    updateSelectDatesModalOpenedByOperation(operation?: GanttAssignmentOperation) {
        this.s.selectDatesModalOpenedByOperation = operation;
    }
}

type Props = RRCProps<GanttAssignmentPageState, GanttAssignmentPageReducers> & { entity: GanttAssignment, entities: GanttAssignmentEntities, resourcesData: ResourcesData, saveEntity?: (entity: any) => void };

export class GanttAssignmentPage extends React.Component<Props, { inputText: string }> {

    protected ganttDoublePageRef = React.createRef<GanttDoublePage>();
    protected copyFlightsFromDbFilter?: Filter = undefined;
    protected tobBarRef: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = { inputText: "" };

        this.updateInputOutputCsv = this.updateInputOutputCsv.bind(this);
        this.copyInputDataFromDb = this.copyInputDataFromDb.bind(this);
    }

    componentDidMount() {
        this.getGanttAssignmentAlgorithms();
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected async componentDidUpdateInternal(prevProps?: Props) {
        if (this.props.entity && !_.isEqual(prevProps?.entity, this.props.entity)) {
            this.props.r.setInReduxState({ selectedAlgorithm: this.props.entity.algorithmType });
        }
        if (!prevProps || !_.isEqual(prevProps.entities, this.props.entities)) {
            this.onChangeEntities();
        }
        if (!prevProps || !_.isEqual(prevProps.resourcesData, this.props.resourcesData)) {
            this.onChangeResourcesData();
        }
        if (prevProps?.s.currentlyUsedResources !== this.props.s.currentlyUsedResources) {
            this.setState({ inputText: String(this.props.s.currentlyUsedResources) });
        }
    }

    private onChangeEntities() {
        this.ganttDoublePageRef.current?.updateEntities(this.props.entities);
    }

    private onChangeResourcesData() {
        this.props.r.setInReduxState({ currentlyUsedResources: this.props.resourcesData.currentlyUsed });
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ hideResources: this.props.resourcesData.hide });
    }

    protected checkIfGanttAssignmentIsReadOnly(alertMsg: string) {
        if (this.props.entity.readOnly) {
            Utils.showGlobalAlert({ message: alertMsg, severity: Severity.INFO });
            return;
        }
    }

    protected async runGanttAssignmentAlgorithm() {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        if (!Object.keys(this.props.s.algorithms).includes(this.props.s.selectedAlgorithm)) {
            Utils.showGlobalAlert({ title: _msg("GanttAssignment.noAlgorithmSelected.title"), message: _msg("GanttAssignment.noAlgorithmSelected.message"), severity: Severity.WARNING });
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: RUN_GANTT_ASSIGNMENT_ALGORITHM, variables: { assignmentId: this.props.entity.id } })).data["ganttAssignmentService_runGanttAssignmentAlgorithm"];
        this.props.saveEntity(entity);
    }

    protected async getGanttAssignmentAlgorithms() {
        const algorithms: { [key: string]: { entityRequirements: GanttAssignmentEntityRequirement[] } } =
            (await apolloClientHolder.apolloClient.query({ query: GET_GANTT_ASSIGNMENT_ALGORITHMS })).data["ganttAssignmentService_ganttAssignmentAlgorithms"];

        Object.values(algorithms).forEach(algorithm => algorithm.entityRequirements
            .forEach(entityRequirement => entityRequirement.defaultAdditionalFilter = Filter.enableAllFilters(entityRequirement.defaultAdditionalFilter)));

        this.props.r.setInReduxState({ algorithms });
    }

    protected async createTasks() {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        if (!this.props.entities["Flight"]) {
            Utils.showGlobalAlert({ title: _msg("GanttAssignment.emptyInput.title"), message: _msg("GanttAssignment.emptyInput.message"), severity: Severity.WARNING });
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: CREATE_TASKS, variables: { assignmentId: this.props.entity.id } })).data["ganttAssignmentService_createTasks"];
        this.props.saveEntity(entity);
    }

    protected async recalculateGanttAssignment(startDate: number, endDate: number) {
        const id = (await apolloClientHolder.apolloClient.mutate({ mutation: RECALCULATE_GANTT_ASSIGNMENT, variables: { assignmentId: this.props.entity.id, startDate: startDate, endDate: endDate } })).data["ganttAssignmentService_recalculateGanttAssignment"];
        Utils.showGlobalAlert({ title: _msg("general.info"), message: _msg("GanttAssignment.operation.message.done"), severity: Severity.CONFIRMATION });
        window.open("#" + ganttAssignmentEntityDescriptor.getEntityEditorUrl(id) + "/gantt");
    }

    protected async deleteOutputDataBetweenDates(startDate: number, endDate: number) {
        const response = (await apolloClientHolder.apolloClient.mutate({ mutation: DELETE_OUTPUT_DATA_FROM_DB, variables: { startDate: startDate, endDate: endDate, algorithmType: this.props.s.selectedAlgorithm, assignmentId: this.props.entity.id } })).data["ganttAssignmentService_deleteOutputDataBetweenDates"];
        Utils.showGlobalAlert({ title: _msg("general.info"), message: response ? _msg("GanttAssignment.operation.message.done") : _msg("GanttAssignment.operation.message.failed"), severity: response ? Severity.CONFIRMATION : Severity.ERROR });
    }

    async copyGanttAssignmentToDbCommon(mode: CopyOutputToDbMode) {
        if (!this.props.entity) {
            return;
        }
        if (mode == CopyOutputToDbMode.SERVICE) {
            await apolloClientHolder.apolloClient.mutate({ mutation: COPY_GANTT_ASSIGNMENT_TO_DB, variables: { assignmentId: this.props.entity?.id } });
        } else if (mode == CopyOutputToDbMode.ENTITY_MANAGER) {
            await apolloClientHolder.apolloClient.mutate({ mutation: COPY_GANTT_ASSIGNMENT_TO_DB_EM, variables: { assignmentId: this.props.entity?.id } });
        }
        Utils.showGlobalAlert({ title: _msg("general.info"), message: _msg("GanttAssignment.operation.message.done"), severity: Severity.CONFIRMATION });
    }

    async copyInputDataFromDb(config: CopyInputDataFromDbConfigInput[]) {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: COPY_INPUT_DATA_FROM_DB, variables: { assignmentId: this.props.entity.id, config } })).data["ganttAssignmentService_copyInputDataFromDb"];
        this.props.saveEntity(entity);
        Utils.showGlobalAlert({ title: _msg("general.info"), message: _msg("GanttAssignment.operation.message.done"), severity: Severity.CONFIRMATION });
    }

    protected updateInputOutputCsv(input: string, output: string) {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        this.props.saveEntity({ ...this.props.entity, inputEntitiesCsv: input, outputEntitiesCsv: output });
    }

    protected okClickOnConfirmationPopup() {
        if (this.props.entity.readOnly) {
            this.props.r.updateConfirmationModalOpenedByOperation();
            return;
        }
        switch (this.props.s.confirmationModalOpenedByOperation) {
            case GanttAssignmentOperation.CREATE_TASKS: {
                this.createTasks();
                break;
            }
            case GanttAssignmentOperation.RUN_ALGORITHM: {
                this.runGanttAssignmentAlgorithm();
                break;
            }
            case GanttAssignmentOperation.COPY_OUTPUT_DATA_TO_DB_SERVICE: {
                this.copyGanttAssignmentToDbCommon(CopyOutputToDbMode.SERVICE);
                break;
            }
            case GanttAssignmentOperation.COPY_OUTPUT_DATA_TO_DB_EM: {
                this.copyGanttAssignmentToDbCommon(CopyOutputToDbMode.ENTITY_MANAGER);
                break;
            }
            default: {
                return;
            }
        }
    }

    protected okClickOnSelectDatesPopup(startDate: number, endDate: number) {
        switch (this.props.s.selectDatesModalOpenedByOperation) {
            case GanttAssignmentOperation.DELETE_OUTPUT_DATA_FROM_DB: {
                this.deleteOutputDataBetweenDates(startDate, endDate);
                break;
            }
            case GanttAssignmentOperation.RECALCULATE: {
                this.recalculateGanttAssignment(startDate, endDate);
                break;
            }
            default: {
                return;
            }
        }
    }

    protected changeInputCurrentlyUsedResources(currentlyUsedResources: number) {
        const resourcesData = this.props.resourcesData;
        if (resourcesData.available === undefined || resourcesData.needed === undefined) {
            return;
        }
        if (currentlyUsedResources < 0) {
            currentlyUsedResources = 0;
        } else if (currentlyUsedResources > resourcesData.available && currentlyUsedResources > resourcesData.needed) {
            currentlyUsedResources = resourcesData.needed > resourcesData.available ? resourcesData.needed : resourcesData.available;
        }

        this.updateCurrentlyUsedEquipmentResources(currentlyUsedResources);
    }

    protected updateCurrentlyUsedEquipmentResources(currentlyUsedResources: number) {
        if (!this.props.entities["EquipmentResource"]) {
            return;
        }
        let hideResources: { [key: string]: number[] } = {};
        if (!hideResources["EquipmentResource"]) {
            hideResources["EquipmentResource"] = [];
        }
        this.props.r.setInReduxState({ currentlyUsedResources });
        hideResources["EquipmentResource"] = this.ganttDoublePageRef.current?.getGanttResourcesRef()?.getResourcesWithTheLeastTimeUsed(currentlyUsedResources) || [];
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ hideResources });
    }


    protected renderTabBar() {
        const readOnly = this.props.entity?.readOnly;
        return <>
            <Button primary icon="bars" onClick={() => this.props.r.setInReduxState({ popupOpened: true })}></Button>
            <ModalExt open={this.props.s.popupOpened} style={{ width: '95%' }} onClose={() => this.props.r.setInReduxState({ popupOpened: false })} >
                <Modal.Content className="wh100">
                    <Segment className="buttonBar EntityEditorFormSimple_bar less-padding less-margin-top-bottom" >
                        <GanttAssignmentDeleteDataButtonRRC id="GanttAssignmentDeleteData" entity={this.props.entity} updateInputOutput={this.updateInputOutputCsv} />
                        <GanttAssignmentCopyInputDataButtonRRC id="GanttAssignmentCopyInputData" entity={this.props.entity} entityRequirements={this.props.s.algorithms[this.props.s.selectedAlgorithm]?.entityRequirements} algorithmName={this.props.s.selectedAlgorithm} copyInputDataFromDb={this.copyInputDataFromDb} />
                        {this.props.s.selectedAlgorithm == AlgorithmType.DYNAMIC_PROGRAMMING ? <Button primary icon="copy" content={_msg("GanttAssignment.createTasks")} onClick={() => this.props.r.updateConfirmationModalOpenedByOperation(GanttAssignmentOperation.CREATE_TASKS)} /> : null}
                        {this.props.s.selectedAlgorithm ? <Button positive content={_msg("GanttAssignment.runAlgorithm")} onClick={() => this.props.r.updateConfirmationModalOpenedByOperation(GanttAssignmentOperation.RUN_ALGORITHM)} /> : null}
                        <Dropdown trigger={<>{_msg("GanttAssingment.copyGanttAssignmentToDb")}</>} icon='dropdown' floating button onClick={() => this.checkIfGanttAssignmentIsReadOnly(_msg("GanttAssingment.copyGanttAssignmentToDb.alreadyPerformed", this.props.entity.id))}>
                            {readOnly ? null :
                                <Dropdown.Menu>
                                    <Dropdown.Item text={CopyOutputToDbMode.SERVICE} onClick={() => this.props.r.updateConfirmationModalOpenedByOperation(GanttAssignmentOperation.COPY_OUTPUT_DATA_TO_DB_SERVICE)} />
                                    <Dropdown.Item text={CopyOutputToDbMode.ENTITY_MANAGER} onClick={() => this.props.r.updateConfirmationModalOpenedByOperation(GanttAssignmentOperation.COPY_OUTPUT_DATA_TO_DB_EM)} />
                                </Dropdown.Menu>}
                        </Dropdown>
                        <Popup key="deleteOutputDataPopup" content={_msg("GanttAssignment.flightsDates.tooltip")} wide position="top center" trigger={<Button negative content={_msg("GanttAssignment.deleteOutputData")} onClick={() => this.props.r.updateSelectDatesModalOpenedByOperation(GanttAssignmentOperation.DELETE_OUTPUT_DATA_FROM_DB)} />} />
                        {this.props.s.selectedAlgorithm == AlgorithmType.DYNAMIC_PROGRAMMING ? <Popup key="recalculatePopup" content={_msg("GanttAssignment.flightsDates.tooltip")} wide position="top center" trigger={<Button positive content={_msg("GanttAssignment.recalculate")} onClick={() => this.props.r.updateSelectDatesModalOpenedByOperation(GanttAssignmentOperation.RECALCULATE)} />} /> : null}

                        <div style={{ flexGrow: 2 }}></div>
                        {this.props.s.selectedAlgorithm == AlgorithmType.DYNAMIC_PROGRAMMING ? <div className="small-margin-right" style={{ display: 'none' }}>
                            {/* // temporary disabled: waiting to see if timeline constraint to have groups with consecutive ids can be removed; if not, we'll need to adjust our code */}
                            <div>{_msg("GanttAssignmentEntityEditor.neededResources", this.props.resourcesData.needed !== undefined ? this.props.resourcesData.needed : "-")}</div>
                            <div>{_msg("GanttAssignmentEntityEditor.availableResources")}: <Input
                                onChange={(e) => this.setState({ inputText: e.target.value })}
                                value={this.state.inputText} onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                        this.changeInputCurrentlyUsedResources(Number(this.state.inputText.trim() || 0));
                                    }
                                }
                                } ><input className='less-padding' style={{ width: 80, textAlign: "center" }} /></Input> <Button icon="plus" compact size="mini" onClick={() => this.changeInputCurrentlyUsedResources(this.props.s.currentlyUsedResources + 1)} />
                                <Button icon="minus" compact size="mini" onClick={() => this.changeInputCurrentlyUsedResources(this.props.s.currentlyUsedResources - 1)} />
                            </div>
                        </div> : null}
                    </Segment>
                    <ModalExt
                        severity={Severity.INFO}
                        open={!Utils.isNullOrEmpty(this.props.s.confirmationModalOpenedByOperation)}
                        content={readOnly ? _msg("GanttAssignment.readOnly.exception.message", this.props.entity?.id) : _msg("GanttAssignment.operation.message.validation")}
                        onClose={() => this.props.r.updateConfirmationModalOpenedByOperation()}
                        actions={[
                            <Button key="close" onClick={() => this.props.r.updateConfirmationModalOpenedByOperation()}>{_msg("general.cancel")}</Button>,
                            <Button key="ok" primary onClick={() => this.okClickOnConfirmationPopup()}>{_msg("general.ok")}</Button>
                        ]}
                    />
                </Modal.Content>
            </ModalExt>
            <SelectDatesPopup open={!Utils.isNullOrEmpty(this.props.s.selectDatesModalOpenedByOperation)} starDate={this.props.entity?.flightsStartDate} endDate={this.props.entity?.flightsEndDate}
                startDateLabel={ganttAssignmentEntityDescriptor.getField("flightsStartDate").getLabel()} endDateLabel={ganttAssignmentEntityDescriptor.getField("flightsEndDate").getLabel()}
                confirmation confirmationMessage={this.props.s.selectDatesModalOpenedByOperation == GanttAssignmentOperation.DELETE_OUTPUT_DATA_FROM_DB ? _msg("GanttAssignment.deleteOutputData.confirmation.message") :
                    (this.props.s.selectDatesModalOpenedByOperation == GanttAssignmentOperation.RECALCULATE ? _msg("GanttAssignment.recalculate.confirmation.message") : "")}
                onClose={() => this.props.r.updateSelectDatesModalOpenedByOperation()}
                onOkClick={(startDate: number, endDate: number) => this.okClickOnSelectDatesPopup(startDate, endDate)}
            />
        </>
    }

    render() {
        const startDate = this.props.entity?.flightsStartDate ? moment(this.props.entity?.flightsStartDate).add(-2, 'hours').toString() : moment().startOf('day').toString();
        const endDate = this.props.entity?.flightsEndDate ? moment(this.props.entity?.flightsEndDate).add(2, 'hours').toString() : moment().endOf('day').toString();
        return <div className="flex-container flex-grow-shrink-no-overflow">
            <Segment className={"flex-container-row flex-center small-padding no-margin gap5"}>
                {this.renderTabBar()}
                <div className="flex-container-row flex-center gap5" ref={this.tobBarRef} />
            </Segment>
            <Segment className="flex-container-row flex-grow less-padding less-margin-top-bottom flex-center">
                <GanttDoublePageRRC id="GanttAssignmentPage_GanttDoublePage" ref={this.ganttDoublePageRef} startDate={startDate} endDate={endDate} portalContainerForTopBar={this.tobBarRef.current} displayMode={GanttDoubleDisplayMode.DEFAULT}
                    ganttDoubleOptions={{
                        ...GANTT_DOUBLE_OPTIONS_PER_ALGORITHM[this.props.s.selectedAlgorithm], ganttTasksOptions: {
                            ...GANTT_DOUBLE_OPTIONS_PER_ALGORITHM[this.props.s.selectedAlgorithm]?.ganttTasksOptions,
                            tableFields: this.props.s.algorithms[this.props.s.selectedAlgorithm]?.entityRequirements.find(r => r.entity === "Flight")?.fields
                        },
                        ganttResourcesOptions: {
                            ...GANTT_DOUBLE_OPTIONS_PER_ALGORITHM[this.props.s.selectedAlgorithm]?.ganttResourcesOptions,
                            tableFields: this.props.s.algorithms[this.props.s.selectedAlgorithm]?.entityRequirements.find(r => r.entity === GANTT_DOUBLE_OPTIONS_PER_ALGORITHM[this.props.s.selectedAlgorithm]?.ganttResourcesOptions.groupBy)?.fields
                        }
                    }} />
            </Segment>
        </div>;
    }
}

type SelectDatesPopupProps = {
    open: boolean,
    startDateLabel: string,
    endDateLabel: string,
    starDate?: number,
    endDate?: number,
    confirmation?: boolean,
    confirmationMessage?: string,
    onClose(): void,
    onOkClick: (startDate: number, endDate: number) => void
}
type SelectDatesPopupState = {
    startDate: number,
    endDate: number,
    confirmationOpened: boolean
}
export class SelectDatesPopup extends React.Component<SelectDatesPopupProps, SelectDatesPopupState> {

    constructor(props: SelectDatesPopupProps) {
        super(props)
        this.state = {
            startDate: moment().valueOf(),
            endDate: moment().endOf('day').valueOf(),
            confirmationOpened: false
        }
    }

    componentDidUpdate(prevProps: Readonly<SelectDatesPopupProps>): void {
        if (prevProps.open != this.props.open) {
            this.setState({ startDate: this.props.starDate || moment().valueOf(), endDate: this.props.endDate || moment().endOf('day').valueOf() });
        }
    }

    protected onOkClick(confirmation?: boolean) {
        if (confirmation) {
            this.setState({ confirmationOpened: true });
            return;
        }
        this.props.onOkClick(this.state.startDate, this.state.endDate);
        this.props.onClose();
    }

    protected onClose() {
        this.setState({ confirmationOpened: false })
        this.props.onClose();
    }

    render() {
        return <>
            <ModalExt size='mini' open={this.props.open} closeIcon onClose={() => this.props.onClose()} >
                <Modal.Content className="wh100">
                    <Segment className="flex-container">
                        <Form>
                            <FormField>
                                <Header textAlign="left" content={this.props.startDateLabel} size="tiny" />
                                <DatePickerReactCalendar onChange={(date: Moment | null) => this.setState({ startDate: date?.valueOf()! })} value={moment(this.state.startDate)} format={Utils.dateTimeFormat} />
                            </FormField>
                            <FormField>
                                <Header textAlign="left" content={this.props.endDateLabel} size="tiny" />
                                <DatePickerReactCalendar onChange={(date: Moment | null) => this.setState({ endDate: date?.valueOf()! })} value={moment(this.state.endDate)} format={Utils.dateTimeFormat} />
                            </FormField>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button key="close" onClick={() => this.onClose()}>{_msg("general.cancel")}</Button>
                    <Button key="ok" positive content={_msg("general.ok")} onClick={() => { this.onOkClick(this.props.confirmation); }} />
                </Modal.Actions>
            </ModalExt>
            <ModalExt
                severity={Severity.CONFIRMATION}
                open={this.state.confirmationOpened}
                content={this.props.confirmationMessage}
                onClose={() => this.onClose()}
                actions={[
                    <Button key="close" onClick={() => this.onClose()}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={() => this.onOkClick()}>{_msg("general.ok")}</Button>
                ]}
            />
        </>
    }
}

export const GanttAssignmentPageRRC = ReduxReusableComponents.connectRRC(GanttAssignmentPageState, GanttAssignmentPageReducers, GanttAssignmentPage);
