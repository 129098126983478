import { Button, Icon, Modal } from "semantic-ui-react";
import { MARK_MESSAGES_TO_REPROCESS } from "./queries";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { EntityDescriptor, EntityTablePage, EntityTablePagePartialProps, EntityTablePageReducers, EntityTablePageState, apolloClientHolder } from "@crispico/foundation-react";

class AgatheMessageOutTablePageState extends EntityTablePageState {
    showModal: boolean = false;
}

class AgatheMessageOutTablePageReducers<S extends AgatheMessageOutTablePageState = AgatheMessageOutTablePageState> extends EntityTablePageReducers<S> { }

type AgatheMessageOutTablePageProps = EntityTablePagePartialProps & RRCProps<AgatheMessageOutTablePageState, AgatheMessageOutTablePageReducers>;

class AgatheMessageOutTablePage extends EntityTablePage<AgatheMessageOutTablePageProps> {

    protected async markMessagesToReprocess() {
        await apolloClientHolder.apolloClient.mutate({
            mutation: MARK_MESSAGES_TO_REPROCESS,
            variables: { filter: this.getCustomQueryDefinitionForLoad().filter },
            context: { showSpinner: true }
        });
        this.refresh();
        this.props.r.setInReduxState({ showModal: false });
    }

    protected renderConfirmMarkMessagesToReprocessModal() {
        return <ModalExt open={ this.props.s.showModal } onClose={() => this.props.r.setInReduxState({ showModal: false })} >
            <Modal.Header><Icon name="info circle"/> {_msg("general.info")}</Modal.Header>
            <Modal.Content className="flex-container">{_msg("AgatheMessageOut.confirmMarkMessagesToReprocess.content")}</Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => this.markMessagesToReprocess()} >{_msg("general.ok")}</Button>
                <Button negative onClick={() => this.props.r.setInReduxState({ showModal: false })}>{_msg("general.cancel")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    renderMain() {
        return <>
            {super.renderMain()}
            {this.renderConfirmMarkMessagesToReprocessModal()}
        </>
    }
    protected preRenderButtons(params: {}): Array<OverrideableElement> {
        return [
            ...super.preRenderButtons(params),
            {
                element: <Button key="reprocessFailedMessages" onClick={() => this.props.r.setInReduxState({ showModal: true })}>{_msg("AgatheMessageOut.reprocessFailedMessages.label")}</Button>
            }
        ];
    }

}

const AgatheMessageOutTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, AgatheMessageOutTablePage);

export class AgatheMessageOutEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "AgatheMessageOut",
            icon: "envelope",
            defaultFilter: Filter.createForClient("creationDate", FilterOperators.forDate.today),
            defaultSort: [{ field: "creationDate", direction: "DESC" }]
        });
    }

    protected customize() {
        this.addFieldDescriptor({ name: "message", type: FieldType.text });
        this.addFieldDescriptor({ name: "responseMessage", type: FieldType.text });
    }

    renderTable() {
        return <AgatheMessageOutTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}
